<template>
  <video-background :src="require('@/assets/video/vidos.mp4')">
    <div class="video-shop-product__content">
      <div class="video-shop-product__info">
        <div class="video-shop-product__flag">
          <img src="@/assets/img/parcel/USA.svg" />
        </div>
        <div class="video-shop-product__title">Nike FlyEase</div>
        <div class="video-shop-product__price">$<span>45</span></div>
      </div>
      <div class="video-shop-product__actions">
        <div class="video-shop-product__avatar">
          <img src="@/assets/img/AvatarProfile.png" />
        </div>
        <div class="video-shop-product__likes">
          <img src="@/assets/img/Like.svg" />
          <b>333</b>
        </div>
        <div class="video-shop-product__share">
          <img src="@/assets/img/Share.svg" />
          <b>342</b>
        </div>
      </div>
      <button class="btnMain green" @click="onBuy">
        {{ $t("buyProductShpNewPu") }}
      </button>
    </div>
  </video-background>
</template>

<script>
import VideoBackground from "@/components/VideoBackground/VideoBackground.vue";

export default {
  name: "VideoShopProduct",
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    VideoBackground,
  },
  methods: {
    onBuy() {
      this.$router.push("add-buyout");
    },
  },
};
</script>

<style lang="scss">
.video-shop-product {
  &__content {
    width: 100%;
    min-height: 200px;
    background: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0) 0%,
      rgba(#141414, 0.6) 20%,
      rgba(#141414, 0.7) 60%
    );
    padding: 16px;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .btnMain {
      width: 100%;
      max-width: 100%;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1 auto;
  }

  &__flag {
    border: 1px solid #f1f1f1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    img {
      max-width: 100%;
    }
  }

  &__title {
    color: #fff;
    flex: 1 auto;
    padding-left: 10px;
    font-family: "Helvetica Neue Medium";
    font-size: 28px;
    padding-top: 4px;
  }

  &__price {
    color: #fff;
    font-size: 16px;
    padding-top: 6px;

    span {
      font-size: 28px;
      font-family: serif;
      font-family: "Helvetica Neue Medium";
      padding-left: 4px;
    }
  }

  &__actions {
    position: absolute;
    bottom: 200px;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
    align-items: flex-end;
  }

  &__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }
  }

  &__likes,
  &__share {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 44px;
    margin-bottom: 16px;

    b {
      color: #fff;
      padding-top: 10px;
      font-size: 12px;
      line-height: 1;
    }
  }
}

.ios .video-shop-product__content {
  padding-bottom: 44px;
  min-height: 234px;
}
</style>