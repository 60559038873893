<template>
  <div class="video-shop">
    <div class="video-shop__header">
      <div class="video-shop__back" @click="$router.push('/')">
        <img src="@/assets/img/arr-l-w.svg" />
      </div>
    </div>
    <div class="video-shop__main">
      <swiper class="swiper parcel-gallery__images" :options="swiperOptions">
        <swiper-slide>
          <video-shop-product />
        </swiper-slide>
        <swiper-slide>
          <video-shop-product />
        </swiper-slide>
        <swiper-slide>
          <video-shop-product />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VideoShopProduct from "./VideoShopProduct.vue";

export default {
  name: "VideoShop",
  props: {
    productId: String,
  },
  components: {
    Swiper,
    SwiperSlide,
    VideoShopProduct,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
        direction: "vertical",
      },
    };
  },
};
</script>

<style lang="scss">
.mainContent.videoshop {
  padding: 0;
}
.video-shop {
  width: 100%;
  height: 100vh;
  background: #000;

  &__main {
    height: 100%;
  }

  .vue-responsive-videobg {
    height: 100vh;
  }

  .video-wrapper {
    z-index: -1;
  }
  
  .videobg-content {
    display: flex;
    align-items: flex-end;
    right: 0;
    z-index: -1;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  &__header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    z-index: 2;
  }

  &__back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ios .video-shop, 
.ios .video-shop .vue-responsive-videobg {
  height: calc(100vh + 95px);
  margin-top: -95px;
}
.ios .video-shop__header {
  margin-top: env(safe-area-inset-top);
}
</style>